/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Minh N. Ta's Portfolio",
  description:
    "A passionate individual who always thrives to create impact through the sexiness of data.",
  og: {
    title: "Minh N. Ta Portfolio",
    type: "website",
    url: "https://tnminh.com",
  },
};

//Home Page
const greeting = {
  title: "Minh N. Ta",
  logo_name: "minhta",
  nickname: "Data & AI Engineer",
  subTitle:
    "A passionate individual who always thrives to create impact through the sexiness of data.",
  resumeLink: "/static/media/MinhTa_AcademicCV_Oct2024.57ec9a98d9c272d3a9d1.pdf",
    //"https://drive.google.com/file/d/1xRpQ4chvr351Ya37bxJulW3BFTmSXEj0/view?usp=share_link",
  portfolio_repository: "https://github.com/ngocminhta/portfolio",
  githubProfile: "https://github.com/ngocminhta",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/ngocminhta",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ngocminh-ta/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
/*   {
    name: "YouTube",
    link: "https://youtube.com/c/DevSense19",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  }, */
  {
    name: "Gmail",
    link: "mailto:minh@tnminh.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
/*   {
    name: "Twitter",
    link: "https://twitter.com/ashutosh_1919",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  }, */
  {
    name: "Facebook",
    link: "https://www.facebook.com/NgocMinhTa.NMT",
    fontAwesomeIcon: "fa-facebook", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
/*   {
    name: "Instagram",
    link: "https://www.instagram.com/layman_brother/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  }, */
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Develop simple models for various machine learning and statistical use cases",
        "⚡ Experience of working with Optimization projects",
        "⚡ Create dynamic report for data analysis and forecasting",
		"⚡ Have a little bit experience in big data storage and processing",
		"⚡ Carrying on a project about Large Language Model",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "skill-icons:pytorch-dark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Scala",
          fontAwesomeClassname: "skill-icons:scala-dark",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Vector database",
          fontAwesomeClassname: "logos:qdrant-icon",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
    		{
          skillName: "Transact-SQL",
          fontAwesomeClassname: "tabler:sql",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
		    {
          skillName: "R",
          fontAwesomeClassname: "mdi:language-r",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
		    {
          skillName: "Langchain",
          fontAwesomeClassname: "simple-icons:langchain",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
		    {
          skillName: "Apache Spark",
          fontAwesomeClassname: "devicon:apachespark-wordmark",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
      ],
    },
	{
      title: "Data Engineering",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Building some functions for data ingestion and data pipeline.",
		"⚡ Deploying Spark applications and Docker containers for data lakehouse projects.",
/*         "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask", */
      ],
      softwareSkills: [
        {
          skillName: "Docker",
          fontAwesomeClassname: "skill-icons:docker",
          style: {
            color: "#E34F26",
          },
        },
		    {
          skillName: "Java",
          fontAwesomeClassname: "skill-icons:java-light",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Scala",
          fontAwesomeClassname: "skill-icons:scala-dark",
          style: {
            backgroundColor: "transparent",
            //color: "#D00000",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            backgroundColor: "transparent",
            //color: "#D00000",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "skill-icons:postgresql-dark",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "skill-icons:mongodb",
          style: {
            backgroundColor: "transparent",
            //color: "#D00000",
          },
        },
        {
          skillName: "Apache Spark",
          fontAwesomeClassname: "devicon:apachespark",
          style: {
            backgroundColor: "transparent",
            //color: "#D00000",
          },
        },
        {
          skillName: "Apache Airflow",
          fontAwesomeClassname: "devicon:apacheairflow",
          style: {
            backgroundColor: "transparent",
            //color: "#D00000",
          },
        },
      ],
    },
    /* {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    }, */
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
/*     {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    }, */
  ],
};

const degrees = {
  degrees: [
    {
      title: "Hanoi University of Science and Technology",
      subtitle: "BSc, Data Science and Artificial Intelligence",
      logo_path: "bk_logo.jpg",
      alt_name: "HUST",
      duration: "2021 - Now",
      descriptions: [
        "⚡ I have studied basic subjects like Data Science, Algorithms, AI, Machine Learning, OOP, etc., and I have done some mini-projects in these subjects, which always receive the grade greater or equal 9/10. I'm also a research student at the AI center.",
        "⚡ Apart from this, I have done courses on Data Science, Cloud Computing and Multitasking Optimization.",
        "⚡ I was selected for Student with Five Good Criteria which is given to top 1% of students in my university.",
		"⚡ I also got the certificate of merit from the university's president for my academic records and contributions to student's developments.",
      ],
      website_link: "http://hust.edu.vn/en/",
    },
  ],
};

const certifications = {
  certifications: [
    /* {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },*/
	{
      title: "Google Professional Data Analytics",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/professional-cert/5G9L24X5GMC7",
      alt_name: "Google DA",
      color_code: "#FFFFFF",
    },
    {
      title: "Big Data & ML on GCP",
      subtitle: "- Google Cloud Skills Boost",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/906c35e8-9a36-4128-8992-31233911b3e1",
      alt_name: "GCP",
      color_code: "#FFFFFF",
    },
	{
      title: "Data Science in Business & Economics",
      subtitle: "- Foreign Trade University",
      logo_path: "ftu.png",
      certificate_link:
        "",
      alt_name: "FTU",
      color_code: "#FFFFFF",
    },
	{
      title: "IBM Data Engineering",
      subtitle: "- IBM",
      logo_path: "ibm.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/professional-cert/D8WYJ5PJNBSV",
      alt_name: "IBM DE",
      color_code: "#FFFFFF",
    },
	{
      title: "Strategic Technology Management",
      subtitle: "- University of Illinos at Urbana–Champaign",
      logo_path: "uiuc.png",
      certificate_link:	
		"https://www.coursera.org/account/accomplishments/specialization/C9DNF3KGGTFU",
      alt_name: "UIUC STM",
      color_code: "#FFFFFF",
    },
	{
      title: "IBM Data Science",
      subtitle: "- IBM",
      logo_path: "ibm.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/professional-cert/5G9L24X5GMC7",
      alt_name: "IBM DS",
      color_code: "#FFFFFF",
    }
  ],
};

// Experience Page
const experience = {
  title: "Experience & Awards",
  subtitle: "Awards, Work, Internship and Volunteership",
  description:
    "I have worked with many projects as AI Engineer, and Project manager. I have also worked several months with companies mostly as Data Analyst and now as Data & AI Engineer. I love organizing events and that is why I am also involved with many open-source communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "🎉 Awards",
      experiences: [
		    {
          title: "Best Presentation Award @ MBZUAI UGRIP Program",
          company: "Project: Machine-generated text detection",
          company_url: "https://soict.hust.edu.vn/",
          logo_path: "mbzuai.jpg",
          duration: "Jun. 2024",
          location: "Abu Dhabi, UAE",
          description:
            "",
          color: "#D83B01",
        },
        {
          title: "Gold Prize @ HUST-SoICT Creative Idea Challenge 2024",
          company: "Project: Code-lleague: Private coding assistant for your own business",
          company_url: "https://soict.hust.edu.vn/",
          logo_path: "logoSOICT.png",
          duration: "Apr. 2024",
          location: "Hanoi, Vietnam",
          description:
            "",
          color: "#D83B01",
        },
        {
          title: "Champion @ Data Science Talent Competition 2022",
          company: "Project: Real-time Traffic Control and Streetlight Management",
          company_url: "https://datapot.vn/hanh-trinh-tim-kiem-tai-nang-khoa-hoc-du-lieu-2022/",
          logo_path: "ftu.png",
          duration: "Oct. 2022",
          location: "Hanoi, Vietnam",
          description:
            "",
          color: "#D83B01",
        },
      ],
    },
    {
      title: "Works",
      experiences: [
        {
          title: "Research resident",
          company: "MBZUAI",
          company_url: "https://mbzuai.ac.ae/",
          logo_path: "mbzuai.jpg",
          duration: "May 2024 - Now",
          location: "Abu Dhabi, UAE and Hanoi, Vietnam (Hybrid)",
          description:
            "I joined the Undergraduate Research Internship program (acceptance rate: 4%) and currently working on the project of Machine-generated text detection under the supervision of professor Preslav Nakov.",
          color: "#181717",
        },
		    {
          title: "Data & AI Engineer",
          company: "Viettel Group",
          company_url: "https://viettel.com.vn/",
          logo_path: "viettel.png",
          duration: "Jul. 2023 - Now",
          location: "Hanoi, Vietnam",
          description: "I joined Viettel Digital Talent program (selection rate of 10%), top 2 best projects of IT division. I'm the only selected interns in AI to work at the headquarters. My current project is building LLM coding Assistant for Data Lakehouse.",
          color: "#000000",
        },
		    {
          title: "Lecturer",
          company: "Datapot JSC.",
          company_url: "https://datapot.vn/",
          logo_path: "datapot.png",
          duration: "Jul. 2023 - Now",
          location: "Hanoi, Vietnam",
          description: "I teach and assist courses about Python and SQL for Data Scientist and Data Analyst. Also, I jointly contribute to build and upgrade curriculum of Data Science Foundation course.",
          color: "#000000",
        },
		    {
          title: "Research Student",
          company: "The International Reseach Center for AI - BK.AI",
          company_url: "https://bkai.ai/",
          logo_path: "bkai.png",
          duration: "Feb. 2022 - Now",
          location: "Hanoi, Vietnam",
          description:
            "Currently, I am a member of Foundation Model Lab focusing developing Vietnamese large language model. Prior to that, I was a member of Modeling, Simulation and Optimization Lab, which focus on developing multitasking algorithms to solve problems in computer science and operation research.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
		{
          title: "Data & AI Engineer Intern",
          company: "Viettel Inc.",
          company_url: "https://viettel.com.vn/",
          logo_path: "viettel.png",
          duration: "Apr. 2023 - Jul. 2023",
          location: "Hanoi, Vietnam",
          description: "I joined the Viettel Digital Talent program in the track of Data Engineering. I'm the only sophomore to be selected in this track. The final project for this program is researching about Data Mesh Architecture.",
          color: "#000000",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
		    {
          title: "Deputy secretary for Study, Scientific Research and Orientation",
          company: "Ho Chi Minh's Youth Union",
          company_url: "https://soict.hust.edu.vn/",
          logo_path: "logoDoan.png",
          duration: "Dec. 2023 - Now",
          location: "Hanoi, Vietnam",
          description:
            "I am the only student on the HUST-SOICT Ho Chi Minh Youth Union Executive Committee in charge of organizing student activities, particularly assisting students in engaging more in studying, scientific research, and acquiring more valuable skills for their future career paths. Also, we connect with companies and corporations to hold some workshops, events that provide them knowledge about practical skills and the requirements of companies.",
          color: "#D83B01",
        },
        {
          title: "Google Developer Students Club Leader",
          company: "GDSC-HUST",
          company_url:
            "https://gdsc.vn",
          logo_path: "gdsc.png",
          duration: "Jul. 2022 - Jul. 2024",
          location: "Hanoi, Vietnam",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organized workshops and activities on Machine Learning, Computer Vision, Natural Language Processing, Flutter and React JS.",
          color: "#0C9D58",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "2408.04284",
      name: "LLM-DetectAIve: a Tool for Fine-Grained Machine-Generated Text Detection",
      createdAt: "2024-08-08T07:43:17Z",
      description: "The 2024 Conference on Empirical Methods in Natural Language Processing: System Demonstration (Co-first author)",
      url:
        "https://arxiv.org/abs/2408.04284",
    },
  ], 
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "ngocminhta.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI Development and Data Analytics and Engineering.",
  },
  blogSection: {
    title: "Blogs",
    subtitle: "Minh codes his life - A place for me to share everything about my life and my work.",
      //"For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blog.tnminh.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "B1-1001, BK.AI Center, Hanoi University of Science and Technology, 1 Dai Co Viet, Hai Ba Trung, Hanoi.",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/ttGu2AtPJnqNd3fNA",
  },
  phoneSection: {
    title: "Personal phone number",
    subtitle: "(+84) 942942968",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
